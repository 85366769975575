import React from 'react'
import { graphql } from 'gatsby'

import Head from '../components/Head'
import Navbar from '../components/navbar'
import Hero from '../components/hero'
import Footer from '../components/footer'
import { languages } from '../i18n/languages'
import allTranslations from '../i18n/translations'
import content from '../components/content'

export default ({ data, pageContext }) => {
  const translations = allTranslations[pageContext.language]

  const menuItems = content.map(({ id }) => ({
    id,
    label: translations[id].menuLabel,
  }))

  const withLanguagePrefix = path => `/${translations.lang}${path}`

  const { frontmatter, html } = data.markdownRemark
  return (
    <div>
      <Head translations={{ ...translations.head, title: frontmatter.title }} />
      <Navbar
        id="top"
        menuItems={menuItems}
        languageItems={languages.filter(
          ({ short }) => short !== translations.lang
        )}
        translations={translations.navbar}
        withLanguagePrefix={withLanguagePrefix}
      />
      <Hero
        id="hero"
        translations={translations.hero}
        image={data.heroImage.childImageSharp}
        hideVideo
      />{' '}
      <div className="container">
        <article className="slptls-content">
          <div
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
        </article>
      </div>
      <Footer
        id="footer"
        translations={translations.footer}
        menuItems={menuItems}
        withLanguagePrefix={withLanguagePrefix}
      />
    </div>
  )
}

export const query = graphql`
  query PageQuery($markdownRemarkId: String!) {
    markdownRemark(id: { eq: $markdownRemarkId }) {
      frontmatter {
        title
        subtitle
      }
      html
    }
    heroImage: file(relativePath: { regex: "/hero.png/" }) {
      childImageSharp {
        sizes(maxWidth: 1920) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
  }
`
